import "../../../styles/BlogSection.css";
const BlogSection = ({ data }) => {
  const { type, varient, content, style } = data;

  if (type === "text") {
    return <span className="section__text">{content}</span>;
  } else if (type === "keyValue") {
    if (varient === "arrow-text") {
      return (
        <div className="section__arrowText">
             <span className="arrow">→</span>
             <div className="arrowText__keyValue">
          <span className="arrowText__key">{content?.key}</span>
          <span className="arrowText__value">{content?.value}</span>
          </div>
        </div>
      );
    } else if (varient === "numberText") {
      return (
        <div className="section__numberText">
          <span className="numberText__key">{content?.key}</span>
          <span className="numberText__value">{content?.value}</span>
        </div>
      );
    } else if (varient === "bulletTextWithKey") {
      return (
        <div className="section__bulletText">
             <span className="dot">•</span>
             <div className="bulletText__keyValue">
          <span className="bulletText__key">{content?.key}</span>
          <span className="bulletText__value">{content?.value}</span>
          </div>
        </div>
      );
    } else {
      return;
    }
  } else if (type == "image") {
    return (
      <div className="section__image">
        <img src={content} alt="image" />
      </div>
    );
  } else if (type === "subTitle") {
    return <h4 className="section__subTitle">{content}</h4>;
  } else if (type === "bulletText") {
    console.log("::style",style)
    return <div className="section__bulletText" style={style}>
     <span className="dot">•</span>
    <span className="bulletText__content">{content}</span>

    </div> 
  } else {
    return;
  }
};
export default BlogSection;
