import { useEffect, useState } from "react";
import "../styles/ScrollToTop.css";
const ScrollTopp = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && (
        <button className="scrollToTop__button" onClick={scrollToTop}>
          <img
            alt="footer-image"
            src="/assets/arrow-up-icon.svg"
            width={"30px"}
            height={"30px"}
          />
        </button>
      )}
    </>
  );
};
export default ScrollTopp;
