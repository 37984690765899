import React from "react";
import "../styles/Footer.css";
import { Container } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

const Footer = () => {
  const scrollToTop = (e) => {
    if(e?.ctrlKey){
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer-bg">
      <Container>
        <div className="footer-img">
          <img alt="footer-image" src="/assets/footer-image.svg" />
        </div>
        <div className="footer__quickLinks">
          <button
            onClick={(e) => {
              scrollToTop(e);
              }}
                 className="footer__button"
          >
              <NavLink
            to="/privacy-policy"
            >
            Privacy Policy
            </NavLink>
          </button>
          <button
            onClick={(e) => {
              scrollToTop(e);
              }}
                 className="footer__button"
          >
            <NavLink
            to="/terms-conditions"
            >
            Terms and Conditions
            </NavLink>
          </button>
          <button
            onClick={(e) => {
              scrollToTop(e);
              }}
                 className="footer__button"
          >
            <NavLink
            to="/disclaimer"
            >
            Disclaimer
            </NavLink>
          </button>
        </div>
        <div className="footer-txt">
          Copyright © 2024 Game Vault Online Game. All Rights Reserved.
        </div>
      </Container>
    </div>
  );
};

export default Footer;
