import React from "react";
import Banner from "../../components/Banner";
import Cards from "../../components/Cards";
import Description from "../../components/Description";
import Download from "../../components/Download";
import ExclusiveFeatures from "../../components/ExclusiveFeatures";
import FaqSection from "../../components/FaqSection";

const messages = [
  "🎉 Sign up today and receive an incredible Cash Back or Welcome Bonus on your first deposit!",
  "🎉 Sign up today and receive an incredible Cash Back or Welcome Bonus on your first deposit!",
];

const Home = () => {
  return (
    <React.Fragment>
      <Banner />
      <Description />
      <ExclusiveFeatures />
      <Cards />
      <Download />
      <FaqSection />
    </React.Fragment>
  );
};
export default Home;
