import React, { useState, useMemo } from "react";
import { cardData } from "../data/CardData";
import { Card, Col, Row, Container } from "reactstrap";
import Pagination from "./Pagination";
import "../styles/Cards.css";

const Cards = () => {
  const PageSize = 16;

  const [currentPage, setCurrentPage] = useState(1);

  const rawData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return cardData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);
  const navigate = (url) => {
    window.open(url, "_blank");
  };
  const navigateToFacebookMessanger = () => {
    const messangerUrl = "https://m.me/61564442363000";
    window.open(messangerUrl, "_blank");
  };
  return (
    <div className="cards-bg">
      <h2 className="official-facebook-pages">OFFICIAL FACEBOOK PAGES</h2>
      <div className="please-contact-our-container">
        <div>Please contact our official</div>
        <div>Partners for account creation</div>
      </div>
      <Container className="d-flex justify-content-center my-sm-2 my-md-3 my-lg-5">
        <Row className="FB-row">
          <Col sm="12" md="6" className="card-gamming-wrapper">
            <Card className="card-gamming facebook-card">
              <div
                className="vip-gaming"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=61551686566639&mibextid=ZbWKwL",
                    "_blank"
                  )
                }
              >
                <p style={{ marginBottom: "0px" }}> VIP GAMING </p>
                <div className="facebook-fill-gv">
                  <img
                    loading="lazy"
                    alt="Facebook Icon"
                    src="/assets/fb-icon.png"
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/profile.php?id=61551686566639&mibextid=ZbWKwL",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
              <div className="vip-gaming-is">
                VIP Gaming is known for unmatched customer service, instant
                cash-in and cash-outs and 24/7 support. Join them and experience
                the thrill of online games.
              </div>
            </Card>
          </Col>
          <Col sm="12" md="6" className="card-gamming-wrapper">
            <Card className="card-gamming facebook-card">
              <div
                className="vip-gaming"
                onClick={() =>
                  window.open(
                    " https://www.facebook.com/people/GAME-VAULT/61564442363000/ ",
                    "_blank"
                  )
                }
              >
                <p style={{ marginBottom: "0px" }}>GAME VAULT</p>
                <div className="facebook-fill-gv">
                  <img
                    loading="lazy"
                    alt="Facebook Icon"
                    src="/assets/fb-icon.png"
                    onClick={() =>
                      window.open(
                        " https://www.facebook.com/people/GAME-VAULT/61564442363000/ ",
                        "_blank"
                      )
                    }
                  />
                </div>
              </div>
              <div className="vip-gaming-is">
                Join Game Vault to receive 200% bonus on the first deposit.
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <h2 className="card-checkout">
          Explore Our Top Games: Fish Games, Slots & Sweepstakes
        </h2>
        <Row className="g-4">
          {rawData?.map((item, index) => (
            <Col xs={12} sm={12} md={6} lg={3} key={index + 1}>
              <Card className="h-100 p-2 card-override">
                <img
                  className="h-100 card-image "
                  alt={index + 1}
                  src={`../assets/game/${item?.image}`}
                />
                <button className="card-button mt-1">{item?.name}</button>
                <div className="card__hover">
                  {" "}
                  <button
                   onClick={navigateToFacebookMessanger}
                  >
                    Play Now
                  </button>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
        <Pagination
          currentPage={currentPage}
          totalCount={cardData?.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Container>
    </div>
  );
};

export default Cards;
