import { Helmet } from "react-helmet";
import "../../styles/TermsAndConditions.css";
import { siteUrl } from "../Blogs/blogData"; 
const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <title>
          {
            "Game Vault 777 Slots - Terms and Conditions"
          }
        </title>
        <meta
          name="description"
          content={
            "The Terms and Conditions outlined here govern your use of the Game Vault website and services."
          }
        />
        <link
          rel="canonical"
          href={`${siteUrl}/terms-and-conditions/game-vault-777-slots-terms-and-conditions`}
        />
        {/* OG Tags */}
        <meta
          property="og:title"
          content={
            "Game Vault 777 Slots - Terms and Conditions"
          }
        />
        <meta property="og:site_name" content="Game Vault 777 Slots/" />
        <meta
          property="og:url"
          content={`${siteUrl}/terms-and-conditions/game-vault-777-slots-terms-and-conditions`}
        />
        <meta
          property="og:description"
          content={
            "The Terms and Conditions outlined here govern your use of the Game Vault website and services."
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={""} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="termsAndConditions__container">
        <h1>Game Vault Terms & Conditions</h1>
        <p>
          The Terms and Conditions outlined here govern your use of the Game
          Vault website and services. Please review them carefully, as they
          establish a legal agreement between you and Game Vault.
        </p>
        <h2>1. Acceptance of Terms:</h2>
        <p>
          By accessing or using the Game Vault website, you agree to adhere to
          these Terms and Conditions. If you do not accept any part of these
          terms, you are not permitted to use our services.
        </p>
        <h2>2. Eligibility:</h2>
        <p>
          To use Game Vault services, you must be at least 18 or meet your
          jurisdiction's legal age requirement. By accessing our platform, you
          confirm that you fulfill this eligibility condition.
        </p>
        <h2>3. Account Registration:</h2>
        <p>
          To access certain features on Game Vault, you may need to create an
          account. During registration, you agree to provide accurate, complete,
          and up-to-date information. Maintaining the confidentiality of your
          account details and all activities conducted under your account is
          your responsibility.
        </p>
        <h2>4. Responsible Gaming:</h2>
        <p>
          At Game Vault, we promote responsible gaming as a source of
          entertainment, not a way to earn money. If you feel you may have a
          gambling problem, we urge you to seek help. To support responsible
          play, we provide tools like deposit limits and self-exclusion options.
        </p>
        <h2>5. Deposits and Withdrawals: </h2>
        <p>
          By making a deposit on Game Vault, you confirm that the funds used are
          legally yours and not acquired unlawfully. All withdrawal requests are
          subject to verification. Game Vault reserves the right to deny or
          cancel withdrawals if fraudulent activity or a breach of these terms
          is suspected.
        </p>
        <h2>6. Bonus Terms:</h2>
        <p>
          Game Vault may provide bonuses and promotions, each governed by
          specific terms and conditions. These rules will be clearly outlined,
          and failure to comply may lead to forfeiture of the bonus and any
          associated winnings.
        </p>
        <h2>7. Fair Play:</h2>
        <p>
          Game Vault is committed to maintaining a secure and fair gaming
          experience. Any form of cheating, collusion, or fraudulent activity is
          strictly prohibited. Players found engaging in such actions risk
          account suspension or termination.
        </p>
        <h2>8. Intellectual Property:</h2>
        <p>
          All content on the Game Vault website, including text, graphics,
          logos, and software, is the exclusive property of Game Vault and
          protected under intellectual property laws. Any duplication,
          distribution, or modification of this material without prior written
          consent is strictly prohibited.
        </p>
        <h2>9. Privacy Policy:</h2>
        <p>
          By accessing Game Vault, you consent to our Privacy Policy, which
          outlines the collection, use, and sharing of your personal
          information. We prioritize your privacy and employ industry-standard
          security practices to protect your data.
        </p>
        <h2>10. Termination of Account:</h2>
        <p>
          Game Vault reserves the right to suspend, restrict, or terminate your
          account at its discretion. This may occur if you violate these Terms
          and Conditions, engage in fraudulent activities, or for any reason
          deemed necessary by Game Vault.
        </p>
        <h2>11. Amendments:</h2>
        <p>
          Game Vault reserves the right to modify these Terms and Conditions at
          any time. It is your responsibility to review them regularly.
          Continued use of our services after any changes indicates your
          acceptance of the updated terms.
        </p>
        <h2>12. Security and Account Integrity:</h2>
        <p>
          Game Vault employs strict security measures to safeguard your account
          and personal information. It is your responsibility to protect your
          login details. If you suspect any unauthorized access to your account,
          contact us immediately. Game Vault cannot be held liable for any
          losses resulting from unauthorized access due to your failure to
          secure your account information.
        </p>
        <h2>13. Prohibited Activities:</h2>
        <p>
          Any activity that threatens the integrity of Game Vault is strictly
          prohibited. This includes, but is not limited to, hacking, attempting
          to disrupt the website’s functionality, and using automated tools or
          software to gain an unfair advantage.
        </p>
        <h2>14. Communication:</h2>
        <p>
          By using Game Vault's services, you agree to receive communications,
          including promotional offers, newsletters, and account updates. You
          can opt out of promotional messages while continuing to receive
          important account notifications.
        </p>
        <h2>15. Third-Party Links:</h2>
        <p>
          Our website may contain links to third-party sites for your
          convenience. However, we do not endorse or control the content on
          these external sites. Game Vault is not responsible for any damages or
          losses resulting from your interactions with third-party websites.
        </p>
        <h2>16. Indemnification:</h2>
        <p>
          By using our services, you agree to indemnify and hold Game Vault,
          along with its affiliates, officers, directors, employees, and agents,
          harmless from any claims, liabilities, damages, or expenses that may
          arise from your use of our services.
        </p>
        <h2>17. Community Guidelines:</h2>
        <p>
          Game Vault is committed to maintaining a positive and inclusive gaming
          environment. We expect all users to adhere to community standards and
          treat each other with respect. Failure to uphold these standards may
          lead to account suspension or termination.
        </p>
        <h2>18. Currency and Exchange Rates:</h2>
        <p>
          Game Vault may offer support for multiple currencies, and exchange
          rates may be applied when converting funds. It is your responsibility
          to stay informed about these rates and any applicable fees associated
          with currency conversion.
        </p>
        <div>
          <p>
            Thank you for reviewing the comprehensive Terms and Conditions of
            Game Vault. We are committed to providing an exceptional gaming
            experience in a secure and responsible environment. Should you have
            any questions or need further clarification, please contact our
            customer support team. Enjoy your gaming journey!
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
